import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import * as Icon from 'react-feather'
import { Helmet } from 'react-helmet'

class IndexPage extends React.PureComponent {
  render() {
    const App = () => {
      return <Icon.Camera />
    }
    return (
      <Layout>
        <Helmet>
          <title>Crowdshopping by About You - Micha Wiebe</title>
          <meta
            charSet="utf-8"
            content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no"
            name="viewport"
          />
          <meta
            name="description"
            content="Hey, I'm Micha Wiebe - UX Designer based in Hamburg and founder of Sketch Wiki. I specialize in product design, user research and data analysis."
          />
          <meta
            name="keywords"
            content="Micha Wiebe, UX Design, Sketch Wiki, Designer in Hamburg"
          />
        </Helmet>
        <body>
          <div className="Header-Menu">
            <a href="/" className="home-header">
              <img
                className="img portfolio-logo"
                alt="Hamburg Header"
                src={require('../images/gatsby-icon.png')}
              />

              <p>micha.design</p>
            </a>
          </div>
          <div className="Skills">
            <div className="no-tile">
              <h1>Crowdshopping</h1>
              <p className="subline-text"> Sublinetext</p>

              <img
                className="img Opener-Image big-screen"
                alt="Hamburg Header"
                src={require('../images/Hamburg-photo.png')}
              />

              <div className="portfolio-article">
                <p>First Absatz</p>
              </div>
            </div>
          </div>
        </body>
      </Layout>
    )
  }
}
export default IndexPage
